import { useInputControl } from '@conform-to/react'
import React, { useId } from 'react'
import { cn } from '#app/utils/misc'
import { Checkbox, type CheckboxProps } from './ui/checkbox.tsx'
import { Input, type InputProps } from './ui/input.tsx'
import { Label } from './ui/label.tsx'
import { Select, type SelectProps } from './ui/select.tsx'
import { Textarea, type TextareaProps } from './ui/textarea.tsx'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
	id,
	errors,
}: {
	errors?: ListOfErrors
	id?: string
}) {
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null
	return (
		<ul id={id} className="flex flex-col gap-1">
			{errorsToRender.map(e => (
				<li key={e} className="text-[10px] text-foreground-destructive">
					{e}
				</li>
			))}
		</ul>
	)
}

export function Field({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: InputProps
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={cn('space-y-1', className, { 'pb-3': !errorId })}>
			<Label htmlFor={id} {...labelProps} />
			<Input
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
			/>
			{errorId ? (
				<div className="min-h-[32px] px-4 pb-3 pt-1">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}

export function TextareaField({
	labelProps,
	textareaProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	textareaProps: TextareaProps
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = textareaProps.id ?? textareaProps.name ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={cn('space-y-1', className, { 'pb-3': !errorId })}>
			<Label htmlFor={id} {...labelProps} />
			<Textarea
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...textareaProps}
			/>
			{errorId ? (
				<div className="min-h-[32px] px-4 pb-3 pt-1">
					{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
				</div>
			) : null}
		</div>
	)
}

export function CheckboxField({
	labelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	buttonProps: CheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
}) {
	const { className: labelClassName, ...otherLabelProps } = labelProps
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()
	const checkedValue = buttonProps.value ?? 'on'
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<div className="flex items-center gap-2">
				<Checkbox
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={state => {
						input.change(state.valueOf() ? checkedValue : '')
						buttonProps.onCheckedChange?.(state)
					}}
					onFocus={event => {
						input.focus()
						buttonProps.onFocus?.(event)
					}}
					onBlur={event => {
						input.blur()
						buttonProps.onBlur?.(event)
					}}
					type="button"
				/>
				<label
					htmlFor={id}
					className={cn(
						'self-center text-sm font-medium leading-none',
						labelClassName,
					)}
					{...otherLabelProps}
				/>
			</div>
			<div className="px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function SelectField({
	labelProps,
	selectProps,
	errors,
	className,
	children,
}: {
	children?: React.ReactNode
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	selectProps: SelectProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
}) {
	const { key, defaultValue, value, id: selectId, ...inputProps } = selectProps
	const fallbackId = useId()
	const id = selectId ?? fallbackId
	const input = useInputControl({
		key,
		name: selectProps.name,
		formId: selectProps.form,
		initialValue: defaultValue ? defaultValue : undefined,
	})
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={cn('space-y-1', className, { 'pb-3': !errorId })}>
			<Label htmlFor={id} {...labelProps} />
			<Select
				id={id}
				key={`select-${id}`}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				defaultValue={defaultValue}
				value={input.value}
				onValueChange={value => input.change(value)}
				{...inputProps}
			>
				{children}
			</Select>
			{errorId ? (
				<div className="min-h-[32px] px-4 pb-3 pt-1">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}

export function FormSection({
	title,
	description,
	children,
	contentClassName,
}: {
	title: string
	description?: string
	children?: React.ReactNode
	contentClassName?: string
}) {
	return (
		<div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
			<div className="px-4 sm:px-0">
				<h2 className="text-lg font-semibold leading-7">{title}</h2>
				{description ? (
					<p className="mt-1 text-sm leading-6 text-gray-600">{description}</p>
				) : null}
			</div>
			<div
				className={cn([
					'bg-white shadow-sm sm:rounded-xl md:col-span-2',
					contentClassName,
				])}
			>
				<div className="px-4 py-6 sm:p-8">
					<div className="grid grid-cols-1 gap-x-4 sm:grid-cols-6">
						{children}
					</div>
				</div>
			</div>
		</div>
	)
}
