import * as SelectPrimitive from '@radix-ui/react-dropdown-menu'
import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '#app/utils/misc.tsx'
import { Icon } from './icon'

export type SelectItemProps = Omit<
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.RadioItem>,
	'type'
> & {
	type?: string
}

const SelectItem = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.RadioItem>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.RadioItem>
>(({ children, className, ...props }, forwardedRef) => {
	return (
		<SelectPrimitive.RadioItem
			className={cn(
				'flex h-10 cursor-pointer select-none items-center rounded-md px-3 py-2 pl-6 text-sm leading-none  data-[disabled]:pointer-events-none data-[highlighted]:bg-primary-30 data-[disabled]:text-muted-foreground data-[highlighted]:outline-none',
				className,
			)}
			{...props}
			ref={forwardedRef}
		>
			<SelectPrimitive.ItemIndicator className="absolute left-2">
				<Icon name="check" />
			</SelectPrimitive.ItemIndicator>
			{children}
		</SelectPrimitive.RadioItem>
	)
})
SelectItem.displayName = SelectPrimitive.Item.displayName

const selectVariants = cva(
	'flex justify-between h-10 w-full rounded-md border border-input px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:bg-primary-30 focus-visible:outline-none focus-visible:ring-3 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid]:border-input-invalid',
	{
		variants: {
			variant: {
				default: 'bg-background focus-visible:ring-secondary-60',
				primary:
					'bg-transparent border-primary-130 focus-visible:ring-primary-130',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	},
)

type RawSelectProps = Omit<
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.Root>,
	'type'
> & {
	type?: string
}

export interface SelectProps
	extends RawSelectProps,
		VariantProps<typeof selectVariants> {
	id: string
	className?: string
	key?: string
	defaultValue?: string
	placeholder?: string
	value?: string
	onValueChange?: (value: string) => void
	renderDisplay?: (value: string | undefined) => void
}

const Select = ({
	defaultValue,
	value,
	onValueChange,
	variant,
	className,
	placeholder,
	children,
	renderDisplay,
	...props
}: SelectProps) => {
	return (
		<SelectPrimitive.Root {...props} modal={false}>
			<SelectPrimitive.Trigger
				id={props.id}
				className={cn(selectVariants({ variant, className }))}
				aria-label={placeholder}
				onScroll={e => e.preventDefault()}
			>
				{renderDisplay?.(value) || value || defaultValue || placeholder}
			</SelectPrimitive.Trigger>
			<SelectPrimitive.Portal>
				<SelectPrimitive.Content
					className="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade min-w-[220px] rounded-md bg-white p-1 shadow-lg will-change-[opacity,transform]"
					sideOffset={5}
					align="start"
				>
					<SelectPrimitive.RadioGroup
						value={value}
						defaultValue={defaultValue}
						onValueChange={value => onValueChange?.(value)}
					>
						{children}
					</SelectPrimitive.RadioGroup>
					<SelectPrimitive.Arrow className="fill-white" />
				</SelectPrimitive.Content>
			</SelectPrimitive.Portal>
		</SelectPrimitive.Root>
	)
}

Select.displayName = SelectPrimitive.Root.displayName

export { Select, SelectItem }
